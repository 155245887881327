import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    blocText: {
      width: 'calc(100% / 3)',
      marginTop: 42,
      boxShadow: '2px 2px 8px 0 rgba(0, 0, 0, 0.2)',
      padding: 6,
      margin: 16,
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    image: {
      height: 230,
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },
    tag: {
      display: 'block',
      paddingTop: 20,
      color: 'white',
      '& span': {
        fontStyle: 'italic',
        background: theme.palette.text.hint,
        padding: '8px 12px',
        fontFamily: '"Playfair Display", serif',
      },
    },
    contentActu: {
      marginTop: 18,
    },
    date: {
      paddingLeft: 16,
      color: theme.palette.secondary.main,
      fontSize: 14,
      fontWeight: 'bold',
    },
    title: {
      padding: '8px 16px 16px 16px',
      fontFamily: theme.typography.body1.fontFamily,
      textTransform: 'uppercase',
      fontSize: 18,
      letterSpacing: 1,
    },
    text: {
      display: 'flex',
      color: '#191919',
      padding: '0 16px 46px 16px',
      '& p': {
        flex: 1,
        lineHeight: '1.38',
      },
    },
    trait: {
      background: 'rgba(96, 197, 219, 0.3)',
      width: 3,
      height: 44,
      marginRight: 16,
    },
    link: {
      marginLeft: 32,
      display: 'flex',
      marginBottom: 28,
      alignItems: 'center',
      '& span': {
        paddingLeft: 8,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'italic',
      },
    },
    traitHorizontal: {
      height: 1,
      background: theme.palette.secondary.main,
      width: 52,
    },
    '@media all and (max-width: 1060px)': {
      blocText: {
        marginLeft: '2%',
        marginRight: '2%',
        minWidth: 300,
        width: '90%',
        '&:first-of-type': {
          marginLeft: '2%',
        },
        '&:last-of-type': {
          marginRight: '2%',
        },
      },
    },
  } as any);

export default styles;
