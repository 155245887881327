import { Button as MaterialButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';
// import { useStyles } from './styles';

interface Props {
  color: 'primary' | 'default';
  variant: 'contained' | 'outlined' | 'text';
  className?: string;
  title?: string;
  onClick?(event: any): void;
  disabled?: boolean;
  classes?: any;
  type?: 'reset' | 'submit' | 'button' | undefined;
}

export function Button(props: Props) {
  const { variant, color, title, classes, onClick, disabled, className, type } = props;
  // const classes = useStyles();
  const propsButton = {
    onClick,
    disabled,
    className,
  };
  return (
    <MaterialButton
      {...propsButton}
      classes={{
        text: classes.text,
      }}
      color={color}
      type={type || 'button'}
      variant={variant}
      className={color === 'primary' ? classes.primaryButton : classes.button}
    >
      {title}
    </MaterialButton>
  );
}

export const ButtonComponent = Button;

export default withStyles(styles)(Button);
