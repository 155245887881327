// polyfill only stable `core-js` features - ES and web standards:
import { ConfigProvider } from '@bc/react-config';
import { CircularProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import 'core-js';
import 'core-js/stable';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime/runtime';
import ReduxProvider from './containers/ReduxProvider';
import Routes from './containers/Routes';
import './index.css';
import { initializeKeycloak } from './services/Authentification/authentification';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import 'whatwg-fetch';

// Locale par defaut : FR
moment.locale('fr');
const handleConfigLoaded = () => {
  initializeKeycloak();
};

ReactDOM.render(
  // tslint:disable-next-line: jsx-wrap-multiline
  <ConfigProvider onLoad={handleConfigLoaded} loading={<CircularProgress />} url="conf/config.json">
    <CookiesProvider>
      <ReduxProvider>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </ReduxProvider>
    </CookiesProvider>
  </ConfigProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
