import { Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      marginTop: 60,
    },
    content: {
      maxWidth: 1400,
      margin: 'auto',
      padding: '0 12px',
      marginBottom: 94,
    },
    contentBureaux: {
      display: 'flex',
      '& > div:first-of-type': {
        marginRight: 24,
      },
      '& > div:last-of-type': {
        marginLeft: 24,
      },
    },
    '@media all and (max-width: 1060px)': {
      contentBureaux: {
        flexDirection: 'column',
        '& > div:first-of-type': {
          margin: 'auto',
          marginBottom: 64,
          marginTop: 46,
        },
        '& > div:last-of-type': {
          margin: 'auto',
          marginBottom: 0,
        },
      },
    },
  } as any);

export default styles;
