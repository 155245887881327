import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import Button from '../../components/Button';
import Layout from '../../components/Layout';
import history from '../../utils/history';
import styles from './styles';

interface Props {
  classes?: any;
  maintenance?: boolean;
}

function NotFoundPage(props: Props) {
  return (
    <Layout>
      <div className={props.classes.container}>
            <Typography variant="h2">
              Cette page n’existe pas ou plus
            </Typography>
          <Typography variant="body1">
            Nous vous prions de nous excuser pour la gêne occasionnée
            <br /> Nous vous invitions à revenir à la page d’accueil de notre
            site.
          </Typography>
          <Button
            title={"Retour à l'accueil"}
            variant="contained"
            onClick={() => history.push('/')}
            color="primary"
          />
      </div>
    </Layout>
  );
}

export default withStyles(styles)(NotFoundPage);
