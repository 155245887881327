import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    header: {
      padding: '12px 64px',
      boxShadow: '3px 3px 4px 0 rgba(0, 0, 0, 0.2)',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: 1400,
        position: 'relative',
      },
    },
    containerTel: {
      display: 'flex',
      '& p, & a': {
        fontSize: 16,
        marginLeft: 20,
      },
      '& p': {
        color: theme.palette.text.hint,
      },
      '& a': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
    trait: {
      background: theme.palette.secondary.main,
      width: 2,
      height: 18,
      marginRight: 20,
    },
    '@media all and (max-width: 580px)': {
      contact: {
        display: 'none',
      },
      header: {
        padding: '12px 24px',
      },
    },
    menuLangue: {
      position: 'absolute',
      top: '-12px',
      right: 0,
    },
    containerUsername: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '& > div': {
        marginRight: 0,
        marginLeft: 32,
      },
    },
    person: {
      marginTop: '-2px',
      marginRight: 8,
      color: theme.palette.text.hint,
    },
    disconnect: {
      marginLeft: 32,
      marginRight: 8,
      color: theme.palette.text.hint,
    },

    '@media all and (max-width: 460px)': {
      logo: {
        height: 50,
      },
      header: {
        '& > div': {
          paddingTop: 18,
        },
      },
      disconnect: {
        marginLeft: 4,
      },
      trait: {
        marginLeft: 4 + ' !important',
      },
    },
  } as any);

export default styles;
