import { Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      marginTop: 140,
    },
    content: {
      maxWidth: 1400,
      padding: '0 12px',
      margin: 'auto',
    },
    contentActus: {
      display: 'flex',
    },

    '@media all and (max-width: 1060px)': {
      contentActus: {
        flexDirection: 'column',
      },
    },
  } as any);

export default styles;
