import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import ReduxPersistConfig from '../config/ReduxPersistConfig';
import history from '../utils/history';

import { reducer as actualitesReducer } from './ActualitesRedux';
import { reducer as contactReducer } from './ContactRedux';

const rootReducer = combineReducers({
  router: connectRouter(history),
  contact: contactReducer,
  actualites: actualitesReducer,
});

// Activation de Redux Persist

let finalReducers = rootReducer;
if (ReduxPersistConfig.active) {
  finalReducers = persistReducer(ReduxPersistConfig.storeConfig, rootReducer);
}

export default finalReducers;
