import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { traduction } from '../../../traductions';
import ImagePresentation from '../../../images/image_mybesse_home.jpg';
import Button from '../../../components/Button';
import Bloc from '../../../components/Bloc';
import history from '../../../utils/history';
import imageCarline from '../../../images/icone_carline.jpg';
import imageMaritime from '../../../images/icone_maritime.jpg';
import imageDoc from '../../../images/icone_document.jpg';
import InformationsBloc from '../../../components/InformationsBloc';
import { getInfoMaintenance } from '../../../services/Config';

interface Props {
  classes?: any;
  langue: string;
}

function PresentationBloc(props: Props) {
  const maintenance = getInfoMaintenance();
  return (
    <div className={props.classes.container}>
      <div className={props.classes.content}>
        <div>
          {maintenance.infoMaintenance === 'true' && (
            <InformationsBloc maintenance={maintenance} langue={props.langue} />
          )}
          <h1 dangerouslySetInnerHTML={{ __html: traduction(props.langue).presentation.textPresentation }} />
          <h3 className={props.classes.subtitle}>{traduction(props.langue).presentation.textPresentation2}</h3>

          <Button
            title={traduction(props.langue).presentation.connexion}
            variant="contained"
            onClick={() => history.push('/espace-personnel')}
            color="primary"
          />

          <p className={props.classes.textPresentation}>{traduction(props.langue).presentation.textPresentation3}</p>
          <div className={props.classes.containerBloc}>
            <Bloc text={traduction(props.langue).presentation.textCarline} image={imageCarline} />
            <Bloc text={traduction(props.langue).presentation.textCargo} image={imageMaritime} />
            <Bloc text={traduction(props.langue).presentation.textDoc} image={imageDoc} />
          </div>
        </div>
        <img className={props.classes.image} src={ImagePresentation} />
      </div>
    </div>
  );
}

export default withStyles(styles)(PresentationBloc);
