import axios, { AxiosResponse, AxiosInstance } from 'axios';
import { UtilitairesApiFactory, NousContacterInput } from './swagger';
import { base64 } from 'base-64';

export interface IApi {
  postNousContacter(params: NousContacterInput): Promise<any>;
}

const create = (endpoint: string, axiosClient: AxiosInstance) => {
  const contactApi = UtilitairesApiFactory({}, endpoint, axiosClient);
  const postNousContacter = (params: NousContacterInput): Promise<any> =>
    new Promise((resolve, reject) => {
      contactApi
        .nousContacter(params)
        .then(() => {
          resolve();
        })
        .catch((error: any) => reject(error));
    });
  return { postNousContacter };
};

export default create;
