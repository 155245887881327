import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { shallowEqual, TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux';

import { Selectors, ActionCreators } from '../../../redux/ActualitesRedux';
import { traduction } from '../../../traductions';
import Types from 'Types';
import bureauParisImg from '../../../images/bureau_paris.jpg';
import { formatDateCourt } from '../../../helpers/formatters';
import BlocActu from '../../../components/BlocActu';
import { useCookies } from 'react-cookie';
import Button from '../../../components/Button';

interface Props {
  classes?: any;
  langue: string;
}

interface Actu {
  datas: {
    title: string;
    intro: string;
    publication_date: string;
    type: {
      label: string;
    };
    url: string;
    img: string;
  };
}
export const useSelector: TypedUseSelectorHook<Types.RootState> = useReduxSelector;
function BureauxBloc(props: Props) {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies();
  const actualites: Actu = useSelector(state => Selectors.getActualitesDatas(state));

  useEffect(() => {
    if (cookies['publicitaireIsCheck'] === 'true') {
      dispatch(ActionCreators.getActualites.request(props.langue || 'fr'));
    }
  }, [cookies]);

  const setCookies = () => {
    setCookie('publicitaireIsCheck', 'true');
  };
  return (
    <div className={props.classes.container}>
      <div className={props.classes.content}>
        <h2>{traduction(props.langue).actualites.titre}</h2>
        <div className={props.classes.contentActus}>
          {actualites ? (
            Object.values(actualites)
              .sort((a, b) => b.publication_date - a.publication_date)
              .map(actu => (
                <BlocActu
                  langue={props.langue}
                  key={actu.title}
                  title={actu && actu.title}
                  text={actu.intro}
                  date={actu.publication_date && formatDateCourt(actu.publication_date)}
                  tag={actu.type.label}
                  link={actu.url}
                  image={actu.img}
                />
              ))
          ) : (
            <p>
              Les actualités ont été bloquées par vos préférences de cookies, pour Les réinitialiser,{' '}
              <Button
                variant="text"
                color="default"
                title="cliquez ici"
                onClick={setCookies}
                className={props.classes.buttonSimple}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(BureauxBloc);
