import moment from 'moment';
import 'moment/locale/fr';
/**
 * Formatte une date sous la forme "22/05/2000"
 *
 * @param date : Date d'entrée a formatter
 */
export const formatDateCourt = (date: any): string => {
  const dateFormat = new Date(Number(date) * 1000);
  const dateIso = dateFormat.toISOString();
  return moment(dateIso).format('DD/MM/YYYY');
};

export const formatDateApi = (date: any): string => {
  const dateFormat = new Date(date);
  return moment(dateFormat).format('DD/MM/YYYY');
};

export const formatYear = (date: any): string => moment(date).format('YYYY');
