import { all, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
/* ------------- Types ------------- */
import { ActionCreators as ActualitesActions } from '../redux/ActualitesRedux';
import { ActionCreators as ContactActions } from '../redux/ContactRedux';
import { getActualites } from './ActualitesSagas';
import Api, { IApi } from '../services/API';
import ApiContact, { IApi as IApiContact } from '../services/APIContact';
import { getActuApiEndpoint, getContactApiEndpoint } from '../services/Config';
import { postNousContacter } from './ContactSagas';

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  const apiActu: IApi = Api.create(getActuApiEndpoint() || 'http://localhost:8090');
  const apiContact: IApiContact = ApiContact.create(getContactApiEndpoint());
  yield all([
    takeLatest(getType(ActualitesActions.getActualites.request), getActualites, apiActu),
    takeLatest(getType(ContactActions.postNousContacter.request), postNousContacter, apiContact),
  ]);
}
