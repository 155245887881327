import { Theme } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';

const styles = (theme: Theme) =>
  ({
    content: {
      margin: 'auto',
      marginTop: 32,
      maxWidth: 1400,
      padding: '0 12px',
      '& h2': {
        marginBottom: 32,
      },
    },
    form: {
      marginBottom: 24,
      '& > div': {
        display: 'block',
        minWidth: 300,
      },
    },
    label: {
      position: 'inherit',
      transform: 'none !important',
      marginBottom: 8,
      fontSize: 14,
    },
    textField: {
      '& legend': {
        display: 'none',
      },
      '& fieldset': {
        top: '0 !important',
      },
      '& input': {
        minWidth: 340,
      },
    },
    confirm: {
      marginBottom: 24,
      width: 'fit-content',
      padding: '6px 24px',
      background: '#c6ffc6',
      borderRadius: '4px',
      color: '#000',
    },
    '@media all and (max-width: 500px)': {
      form: {
        '& input': {
          width: '100%',
        },
        '& > div': {
          width: '100%',
          minWidth: 300,
          display: 'flex',
        },
        '& > button': {
          margin: 'auto',
          display: 'block',
        },
      },
    },
  } as any);

export default styles;
