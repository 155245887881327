import { Theme } from '@material-ui/core/styles';
import image from '../../images/background.jpg';

const styles = (theme: Theme) =>
  ({
    container: {
      background: "linear-gradient(70deg, rgba(0, 85, 156, 0.8) 50%, rgba(1, 42, 101, 1)), url('" + image + "')",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 64,
      minHeight: 'calc(100%)',
    },
    content: {
      margin: 'auto',
      maxWidth: 1400,
      '& h2': {
        fontSize: 40,
        marginBottom: 12,
        color: 'white',
        paddingLeft: 0,
        '&:before': {
          content: 'none',
        },
      },
    },
    styleText: {
      fontStyle: 'italic',
      letterSpacing: 'normal',
    },
    contentApp: {
      marginTop: 64,
      flexWrap: 'wrap',
      display: 'flex',
      '& > div': {
        marginRight: 12,
        marginBottom: 12,
      },
    },
  } as any);

export default styles;
