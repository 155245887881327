import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';
// import { useStyles } from './styles';

interface Props {
  title: string;
  text: string;
  classes?: any;
  image: any;
}

function BlocTextImg(props: Props) {
  const { text, title, classes, image } = props;
  return (
    <div className={classes.blocText}>
      <div>
        <h4>{title}</h4>
        <div className={classes.text}>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
      <img src={image} width={290} height={290} />
    </div>
  );
}

export const BlocTextImgComponent = BlocTextImg;
export default withStyles(styles)(BlocTextImg);
