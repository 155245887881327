import React from 'react';
import { withStyles, Link } from '@material-ui/core';
import styles from './styles';
import { traduction } from '../../traductions/index';

interface Props {
  classes?: any;
  langue: string;
}

function Footer(props: Props) {
  return (
    <div className={props.classes.footer}>
      <div>
        <div className={props.classes.menuFooter}>
          <Link href="/contact">{traduction(props.langue).footer.contact}</Link>
          <Link href="/mentions-legales">{traduction(props.langue).footer.mentions}</Link>
          <Link href="/donnees-personnelles">{traduction(props.langue).footer.donneesPerso}</Link>
        </div>
        <div className={props.classes.copyright}>
          <p>{traduction(props.langue).footer.copyright}</p>
        </div>
      </div>
    </div>
  );
}

export const FooterComponent = Footer;
export default withStyles(styles)(Footer);
