import { ActionType, createAsyncAction, getType, createAction } from 'typesafe-actions';

import Types from 'Types';

// Types

export interface ActualitesState {
  datas?: any;
  actusFetchInfos: Types.IFetchInfos;
}

// Initial state

export const initialState: ActualitesState = {
  datas: undefined,
  actusFetchInfos: {
    fetching: false,
    error: undefined,
  },
};

// Action types and action creators

const getActualites = createAsyncAction(
  '@@app/actualites/GET_ACTUALITES_REQUEST',
  '@@app/actualites/GET_ACTUALITES_SUCCESS',
  '@@app/actualites/GET_ACTUALITES_FAILURE',
)<string, any, Types.WrappedError>();

export const ActionCreators = {
  getActualites,
};

// Selectors
const getActualitesDatas = (state: Types.RootState): any | undefined => state.actualites && state.actualites.datas;
const getActualitesFetching = (state: Types.RootState): boolean =>
  state.actualites && state.actualites.actusFetchInfos.fetching;

export const Selectors = {
  getActualitesDatas,
  getActualitesFetching,
};
export const reducer = (
  state: ActualitesState = initialState,
  action: ActionType<typeof ActionCreators>,
): ActualitesState => {
  switch (action.type) {
    case getType(getActualites.request):
      return {
        ...state,
        datas: undefined,
        actusFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(getActualites.success):
      return {
        ...state,
        datas: action.payload,
        actusFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(getActualites.failure):
      return {
        ...state,
        actusFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
