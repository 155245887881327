import React, { KeyboardEvent, MouseEvent } from 'react';
import {
  Link,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { traduction } from '../../traductions';
import Button from '../Button';
// import { useStyles } from './styles';

interface Props {
  isOpen?: boolean;
  title: string;
  text: string;
  classes?: any;
  onClick?(): void;
  hasButton?: boolean;
  titleButton?: string;
  disabledBackDrop?: boolean;
  dateFin?: string;
}

function Modale(props: Props) {
  const [open, setOpen] = React.useState(props.isOpen || false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { title, text, onClick, hasButton, titleButton, classes, disabledBackDrop, dateFin } = props;
  return (
    <div>
      {hasButton && <Button variant="outlined" color="primary" onClick={handleClickOpen} title={titleButton} />}
      <Dialog
        disableBackdropClick={disabledBackDrop}
        disableEscapeKeyDown={disabledBackDrop}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        classes={{
          paper: classes.paperModale,
        }}
      >
        <DialogContent
          classes={{
            root: classes.rootModale,
          }}
        >
          <>
            <h2>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: text }} />
            <br />
            <p>{dateFin}</p>
          </>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} variant="contained" color="primary" title={titleButton} />
          <Button onClick={handleClose} color="primary" variant="outlined" title={'lggz'} /> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export const ModaleComponent = Modale;
export default withStyles(styles)(Modale);
