import React from 'react';
import Modale from '../../../components/Modale';
import { formatDateApi } from '../../../helpers/formatters';
import { traduction } from '../../../traductions';

interface Props {
  classes?: any;
  langue: string;
  maintenance: any;
}

function MaintenanceBloc(props: Props) {
  const { maintenance } = props;
  const delaiMaintenance =
    maintenance.dateFinMaintenance &&
    'Votre site sera de nouveau disponible le ' + formatDateApi(maintenance.dateFinMaintenance);
  return (
    <Modale
      isOpen={true}
      title={traduction(props.langue).maintenance.titre}
      text={maintenance.messageMaintenanceFr}
      dateFin={delaiMaintenance}
      disabledBackDrop
    />
  );
}

export default MaintenanceBloc;
