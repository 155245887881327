import { withStyles } from '@material-ui/styles';
import React from 'react';
import { formatDateApi } from '../../helpers/formatters';
import { traduction } from '../../traductions';
import styles from './styles';
// import { useStyles } from './styles';

interface Props {
  classes?: any;
  maintenance?: any;
  langue: string;
}

function InformationsBloc(props: Props) {
  const { classes, maintenance } = props;
  return (
    <div className={classes.contentInfo}>
      <div className={classes.trait} />
      <div className={classes.text}>
        <h5 className={classes.title}>{traduction(props.langue).maintenance.titreInfo}</h5>
        {maintenance.dates && (
          <p>
           {maintenance.dates}
          </p>
        )}
        <p dangerouslySetInnerHTML={{ __html: maintenance.messageInfoFr }} />
      </div>
    </div>
  );
}

export const InformationsBlocComponent = InformationsBloc;
export default withStyles(styles)(InformationsBloc);
