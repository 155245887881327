import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    contentInfo: {
      display: 'flex',
      color: 'white',
      '& h5': {
        // color: theme.palette.error.main,
        textTransform: 'uppercase',
        marginBottom: 12,
      },
      '& p': {
        marginTop: 4,
      },
    },
    text: {
      flex: 1,
      marginLeft: 20,
    },
    trait: {
      background: theme.palette.error.main,
      opacity: 0.8,
      width: 4,
    },
  } as any);
// tslint:enable: quotemark

export default styles;
