import Keycloak, { KeycloakConfig, KeycloakError, KeycloakInstance } from 'keycloak-js';
import { getKeycloakClientId, getKeycloakRealm, getKeycloakUrl } from '../Config';

let keycloak: KeycloakInstance;

/**
 * Initialise l'instance Keycloak
 * @param config
 */
export const initializeKeycloak = () => {
  const keycloakConfiguration: KeycloakConfig = {
    realm: getKeycloakRealm(),
    url: getKeycloakUrl(),
    //'ssl-required': true,
    clientId: getKeycloakClientId(),
    // 'public-client': true,
    // 'confidential-port': 0,
  };
  keycloak = Keycloak(keycloakConfiguration);
};

/**
 * Contrôle si l'utilisateur est connecté et lui affiche l'écran de connexion si nécessaire.
 */
export const loginIfRequired = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    keycloak
      .init({ onLoad: 'login-required' })
      .then((authenticated: boolean | PromiseLike<boolean>) => {
        resolve(authenticated);
      })
      .catch((error: KeycloakError) => {
        console.log('an error has occurred');
        console.log(error);
        reject(error);
      });
  });

/**
 * Déconnexion de l'utilisateur
 */
export const logout = () => {
  const { protocol, port, hostname } = window.location;
  const redirectUri = protocol + '//' + hostname + (port !== '' ? ':' + port : '');
  keycloak.logout({
    redirectUri,
  });
  sessionStorage.clear();
};

/**
 * Récupération du nom de la personne connectée
 */
export const getUsername = () => {
  return (
    // keycloak.idTokenParsed && keycloak.idTokenParsed['family_name'] + ' ' + keycloak.idTokenParsed['given_name']
    keycloak.idTokenParsed && keycloak.idTokenParsed['preferred_username']
  );
};

/**
 * Récupération du nom de la personne connectée
 */
export const getNameToDisplay = () => {
  return keycloak.idTokenParsed && keycloak.idTokenParsed['given_name'] + ' ' + keycloak.idTokenParsed['family_name'];
};

/**
 * Retourne l'access token stocké par Keycloak
 */
// export const getToken = (): string | undefined => keycloak.token;

/**
 * Détermine si un utilisateur est connecté
 */
export const isAuthenticated = (): boolean | undefined => {
  return keycloak.authenticated;
};

/**
 * Récupération des roles
 */
export const getRoles = () => {
  return keycloak.realmAccess && keycloak.realmAccess.roles;
};
