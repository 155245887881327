/**
 * Création du store Redux
 */
import { History } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import reducers from './rootReducers';

export default function configureStore(initialState = {}, history: History) {
  const sagaMiddleware = createSagaMiddleware();

  // On définit deux middlewares :
  // 1. sagaMiddleware: pour faire fonctionner redux-saga
  // 2. routerMiddleware: pour synchroniser URL paths avec le state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // Si l'extension Redux DevTools est installée on l'utilise
  // Sinon on utilise le compose de Redux

  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  // Création du store
  const store = createStore(reducers, initialState, composeEnhancers(...enhancers)) as any;

  // On ajoute une extension runSaga pour pouvoir démarrer le middleware saga après la création du store
  store.runSaga = sagaMiddleware.run;

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./rootReducers', () => {
      store.replaceReducer(reducers);
    });
  }

  return store;
}
