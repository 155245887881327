import { useEffect } from "react";
import { useCookies } from 'react-cookie';
export const AXPETIO_COOKIE_NAME = 'MyBesseCookiesIsCheck';
export const MATOMO_SESSION_NAME = 'matomoTrackingEnabled';

const AxeptioInjector = (clientId: string) => {
    const [cookies] = useCookies();
    const cookie = cookies[AXPETIO_COOKIE_NAME]
    sessionStorage.setItem(MATOMO_SESSION_NAME, 'false');
    if (cookie && cookie.Matomo) {
        // Inject Matomo tracking session based on axptio cookie once Matomo is enabled
        sessionStorage.setItem(MATOMO_SESSION_NAME, 'true');
    }

    window._axcb = window._axcb || [];
    window._axcb.push((axeptio: any) => {
        axeptio.on("cookies:complete", (choices: any) => {
            if (choices.Matomo) {
                if (sessionStorage.getItem(MATOMO_SESSION_NAME) === 'false') {
                    sessionStorage.setItem(MATOMO_SESSION_NAME, 'true');
                    window.location.reload();
                }
            } else {
                if (sessionStorage.getItem(MATOMO_SESSION_NAME) === 'true') {
                    sessionStorage.removeItem(MATOMO_SESSION_NAME);
                    window.location.reload();
                }
            }
        });
    });

    useEffect(() => {
        window.axeptioSettings = {
            clientId,
            cookiesVersion: "mybesse pro-fr",
            jsonCookieName: AXPETIO_COOKIE_NAME,
            userCookiesDuration: 180
        };
        // Create axpetio popup and append it to HTML body
        const element = document.querySelector('body')
        const script = document.createElement('script')
        script.src = '//static.axept.io/sdk-slim.js'
        script.async = true
        element && element.appendChild(script)
        return () => {
            element && element.removeChild(script)
        }

    }, []);

    return null;
};

export default AxeptioInjector;