import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    simpleMenu: {
      background: theme.palette.primary.main,
      borderRadius: 0,
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
    dropdown: {
      background: theme.palette.primary.main,
      color: 'white',
      padding: '0 6px 0 5px',
      borderRadius: 0,
      top: '37px !important',
      width: 53,
      '& .MuiMenuItem-root': {
        fontFamily: 'DIN-Regular',
        fontSize: 15,
        padding: 0,
        paddingLeft: 5,
      },
    },
  } as any);

export default styles;
