import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import MentionsLegales from './MentionsLegales';
import DonneesPersonnelles from './DonneesPersonnelles';
import Contact from './Contact';
import EspacePersonnel from './EspacePersonnel';
import PrivateRoute from '../components/PrivateRoute';
import NotFoundPage from './NotFoundPage';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/mentions-legales" exact component={MentionsLegales} />
        <Route path="/donnees-personnelles" exact component={DonneesPersonnelles} />
        <Route path="/contact" exact component={Contact} />
        {/* Private Route*/}
        <PrivateRoute path="/espace-personnel" exact component={EspacePersonnel} />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default Routes;
