import axios, { AxiosResponse } from 'axios';

export interface IApi {
  // TODO typage
  getActualites(langue: string): Promise<any>;
}

const create = (endpoint: string) => {
  const getActualites = (langue: string): Promise<any> =>
    new Promise((resolve, reject) => {
      axios
        .get(endpoint + 'list', {
          params: {
            // TODO Ajouter ce token dans les configmap
            besse_token: 'lmw-test-token',
            count: 3,
            type: ['publication', 'actualite', 'evenement', 'expert'],
            sector: [45, 46, 5, 4, 44],
            language: langue,
          },
        })
        .then((response: AxiosResponse<any>) => {
          resolve(response.data.result);
        })
        .catch((error: any) => reject(error));
    });
  return { getActualites };
};

export default create;
