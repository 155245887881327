// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * ApiContact
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    nom?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    prenom?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    societe?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InfoAppli
 */
export interface InfoAppli {
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    objetMail: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    templateHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    templateFooter?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    templateMail: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    senderName?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoAppli
     */
    destinataire?: string;
}
/**
 * Paramètre d\'entrée invalide
 * @export
 * @interface InvalidParameter
 */
export interface InvalidParameter {
    /**
     * Correspond au type d\'erreur
     * @type {string}
     * @memberof InvalidParameter
     */
    code: string;
    /**
     * Correspond au nom du champ invalide
     * @type {string}
     * @memberof InvalidParameter
     */
    field: string;
    /**
     * Correspond au message d\'erreur
     * @type {string}
     * @memberof InvalidParameter
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface NousContacterInput
 */
export interface NousContacterInput {
    /**
     * 
     * @type {Contact}
     * @memberof NousContacterInput
     */
    contact: Contact;
    /**
     * 
     * @type {InfoAppli}
     * @memberof NousContacterInput
     */
    infoAppli: InfoAppli;
}

/**
 * UtilitairesApi - axios parameter creator
 * @export
 */
export const UtilitairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {NousContacterInput} nousContacterInput Infos à envoyer à l\&#39;équipe projet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacter(nousContacterInput: NousContacterInput, options: any = {}): RequestArgs {
            // verify required parameter 'nousContacterInput' is not null or undefined
            if (nousContacterInput === null || nousContacterInput === undefined) {
                throw new RequiredError('nousContacterInput','Required parameter nousContacterInput was null or undefined when calling nousContacter.');
            }
            const localVarPath = `/nous-contacter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"NousContacterInput" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(nousContacterInput !== undefined ? nousContacterInput : {}) : (nousContacterInput || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilitairesApi - functional programming interface
 * @export
 */
export const UtilitairesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {NousContacterInput} nousContacterInput Infos à envoyer à l\&#39;équipe projet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacter(nousContacterInput: NousContacterInput, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UtilitairesApiAxiosParamCreator(configuration).nousContacter(nousContacterInput, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UtilitairesApi - factory interface
 * @export
 */
export const UtilitairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Nous contacter
         * @param {NousContacterInput} nousContacterInput Infos à envoyer à l\&#39;équipe projet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nousContacter(nousContacterInput: NousContacterInput, options?: any) {
            return UtilitairesApiFp(configuration).nousContacter(nousContacterInput, options)(axios, basePath);
        },
    };
};

/**
 * UtilitairesApi - object-oriented interface
 * @export
 * @class UtilitairesApi
 * @extends {BaseAPI}
 */
export class UtilitairesApi extends BaseAPI {
    /**
     * 
     * @summary Nous contacter
     * @param {NousContacterInput} nousContacterInput Infos à envoyer à l\&#39;équipe projet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilitairesApi
     */
    public nousContacter(nousContacterInput: NousContacterInput, options?: any) {
        return UtilitairesApiFp(this.configuration).nousContacter(nousContacterInput, options)(this.axios, this.basePath);
    }

}


