import React, { useEffect } from 'react';
import { withStyles, TextField, CircularProgress } from '@material-ui/core';
import styles from '../styles';
import InputField from '../../../components/inputs/InputField';
import Button from '../../../components/Button';
import { useForm, Controller } from 'react-hook-form';
import { Contact } from '../../../services/APIContact/swagger';
import { traduction } from '../../../traductions';
interface Props {
  classes?: any;
  onSubmitForm(params: Contact): void;
  fetching?: boolean;
  langue: string;
}

function FormContact(props: Props) {
  const { handleSubmit, errors, register } = useForm();
  const onSubmit = data => props.onSubmitForm(data);

  const message = traduction(props.langue).contact.messageObligatoire;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={props.classes.form}>
      <InputField
        inputRef={register({
          required: {
            value: true,
            message,
          },
        })}
        label={traduction(props.langue).contact.nom}
        name="nom"
        error={errors.nom && errors.nom.message}
      />
      <InputField
        inputRef={register({
          required: {
            value: true,
            message,
          },
        })}
        label={traduction(props.langue).contact.societe}
        name="societe"
        error={errors.societe && errors.societe.message}
      />
      <InputField
        inputRef={register({
          required: {
            value: true,
            message,
          },
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Merci de revoir le format de l'email",
          },
        })}
        label={traduction(props.langue).contact.email}
        name="email"
        error={errors.email && errors.email.message}
      />{' '}
      <InputField
        inputRef={register({
          required: {
            value: true,
            message,
          },
        })}
        label={traduction(props.langue).contact.telephone}
        name="telephone"
        error={errors.telephone && errors.telephone.message}
      />{' '}
      <InputField
        inputRef={register({
          required: {
            value: true,
            message,
          },
        })}
        multiline
        rows={4}
        label={traduction(props.langue).contact.message}
        name="message"
        error={errors.message && errors.message.message}
      />
      {props.fetching ? (
        <span>
          <CircularProgress />
        </span>
      ) : (
        <Button type="submit" variant="contained" color="primary" title={traduction(props.langue).contact.envoi} />
      )}
    </form>
  );
}

export default withStyles(styles)(FormContact);
