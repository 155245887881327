import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    footer: {
      padding: '32px 12px 22px 12px',
      boxShadow: 'inset 0 2px 5px 0 rgba(0, 0, 0, 0.12)',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: 1400,
        '& p': {
          fontSize: 12,
          color: '#7A7A7A',
        },
        flexWrap: 'wrap',
      },
    },
    menuFooter: {
      display: 'flex',
      '& a': {
        color: '#7A7A7A',
        fontSize: 12,
        marginRight: 54,
      },
    },
    '@media all and (max-width: 580px)': {
      footer: {
        '& > div': {
          flexDirection: 'column',
        },
      },
      copyright: {
        margin: 'auto',
        marginTop: '20px',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      menuFooter: {
        '& a': {
          margin: '0 4px 0 4px',
          textAlign: 'center',
        },
      },
    },
  } as any);

export default styles;
