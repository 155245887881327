import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    bloc: {
      background: 'white',
      height: 'calc(220px - 64px)',
      width: 'calc(198px - 72px)',
      borderRadius: 4,
      boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
      padding: '36px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& p': {
        color: theme.palette.primary.main,
        lineHeight: 1.13,
        fontWeight: 700,
        marginTop: 26,
      },
      '& a': {
        marginTop: 12,
        width: 35,
        height: 35,
        minHeight: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        color: 'white',
      },
      '& img': {
        maxWidth: 116,
      },
    },
    blocWithLink: {
      padding: '36px 36px 20px 36px',
      height: 'calc(220px - 32px)',
    },
    linkOk: {
      background: '#26d36e', // #00da84
    },
    linkNotOk: {
      background: theme.palette.error.main,
    },
    info: {
      background: theme.palette.secondary.main,
      marginTop: 12,
      width: 35,
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      color: 'white',
      '& > span': {
        fontFamily: 'Georgia',
        fontStyle: 'italic',
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: '12px 18px',
      maxWidth: 400,
    },
    '@media all and (max-width: 700px)': {
      bloc: {
        width: 'calc(100% - 24px)',
        padding: 12,
        height: 'auto',
      },
    },
  } as any);

export default styles;
