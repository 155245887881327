import { TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';
import classes from '*.module.css';
// import { useStyles } from './styles';

interface Props {
  classes?: any;
  label: string;
  required?: boolean;
  error?: string;
  value?: string;
  inputRef: any;
  name: string;
  multiline?: boolean;
  rows?: number;
}

export function InputField(props: Props) {
  const { name, label, inputRef, error, multiline, rows, classes } = props;
  const defaultProps = {
    className: classes.textField,
    InputLabelProps: {
      shrink: true,
      color: 'primary',
      className: classes.label,
    },
  };

  return (
    <>
      <TextField
        variant="outlined"
        {...defaultProps}
        label={label}
        name={name}
        multiline={multiline}
        rows={rows}
        autoComplete={name}
        error={error !== undefined}
        inputRef={inputRef}
      />
      <Typography color="error" className={classes.textError}>
        {<p>{error && error}</p>}
      </Typography>
    </>
  );
}

export const InputFieldComponent = InputField;

export default withStyles(styles)(InputField);
