import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      marginTop: 24,
    },
    content: {
      maxWidth: 950,
      padding: '0 12px',
      margin: 'auto',
      '& h2': {
        marginBottom: 12,
      },
      '& h4': {
        marginTop: 24,
        marginBottom: 12,
      },
      '& p': {
        paddingLeft: 24,
        position: 'relative',
        lineHeight: '1.5em',
      },
      '& li': {
        marginBottom: 12,
        listStyle: 'none',
      },
    },
  } as any);

export default styles;
