import { Theme } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      background: 'linear-gradient(231deg, #00559c 82%, #012b65)',
      padding: 64,
      maxHeight: 'calc(779px - 128px)',
      '& h1': {
        marginTop: 32,
      },
    },
    content: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 1400,
    },
    image: {
      boxShadow: '7px 7px 16px 0 rgba(0, 0, 0, 0.28)',
      height: 779,
    },
    subtitle: {
      marginTop: 32,
      marginBottom: 32,
      maxWidth: 650,
      lineHeight: 1.3,
    },
    containerBloc: {
      display: 'flex',
      marginTop: 30,
      '& > div': {
        marginRight: 12,
      },
    },
    textPresentation: {
      marginTop: 94,
      color: 'white',
    },
    '@media all and (max-width: 1270px)': {
      container: {
        paddingTop: 32,
        maxHeight: 'calc(600px - 80px)',
      },
      image: {
        height: 600,
      },
      textPresentation: {
        marginTop: 32,
      },
    },
    '@media all and (max-width: 1130px)': {
      image: {
        display: 'none',
      },
    },
    '@media all and (max-width: 700px)': {
      container: {
        padding: 24,
        maxHeight: '100%',
      },
      containerBloc: {
        flexDirection: 'column',
        '& > div': {
          marginRight: 0,
          marginBottom: 12,
        },
      },
    },
  } as any);

export default styles;
