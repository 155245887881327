import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    container: {
      background: 'linear-gradient(231deg, #00559c 82%, #012b65)',
      padding: 64,
      paddingLeft: 168,
      height: 'calc(100vh - 324px)',
      '& h2, & p': {
        color: 'white',
      },
      "& p": {
        marginTop: 24,
        marginBottom: 12,
        fontSize: 18,
      }
    },
  } as any);

export default styles;
