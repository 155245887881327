import storageSession from 'redux-persist/lib/storage/session';

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const config = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'primary',
    storage: storageSession,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['form', 'router', 'error'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    // whitelist: [],
    // transforms: [immutablePersistenceTransform],
  },
};

export default config;
