import { Theme } from '@material-ui/core/styles';

// tslint:disable: quotemark
const styles = (theme: Theme) =>
  ({
    label: {
      position: 'inherit',
      transform: 'none !important',
      marginBottom: 8,
      fontSize: 14,
    },
    textField: {
      marginBottom: 0,
      '& legend': {
        display: 'none',
      },
      '& fieldset': {
        top: '0 !important',
      },
      '& input, & textarea': {
        minWidth: 340,
      },
    },
    textError: {
      fontSize: 13,
      marginBottom: 24,
    },
  } as any);
// tslint:enable: quotemark

export default styles;
