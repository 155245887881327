import React, { KeyboardEvent, MouseEvent } from 'react';
import { Button as MaterialButton, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { traduction } from '../../traductions';
// import { useStyles } from './styles';

interface Props {
  title: string;
  text: string;
  classes?: any;
  image: any;
  tag?: string;
  date?: string;
  link?: string;
  langue: string;
}

function BlocActu(props: Props) {
  const { text, title, classes, image, tag, date, link, langue } = props;
  return (
    <div className={classes.blocText}>
      <div>
        <div className={classes.image} style={{ backgroundImage: "url('" + image + "')" }}>
          <span className={classes.tag}>
            <span>{tag}</span>
          </span>
        </div>
        <div className={classes.contentActu}>
          <span className={classes.date}>{date}</span>
          <h4 className={classes.title}>{title}</h4>
          <div className={classes.text}>
            <div className={classes.trait} />
            <p dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          <Link href={link} className={classes.link} target="_blank">
            <div className={classes.traitHorizontal} />
            <span>{traduction(langue).actualites.lirePlus}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export const BlocActuComponent = BlocActu;
export default withStyles(styles)(BlocActu);
