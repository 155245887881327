import { getConfig } from '@bc/react-config';

export const getKeycloakUrl = () => {
    return getConfig('REACT_APP_MYBESSE_KEYCLOAK_URL');
};

export const getKeycloakRealm = (): string => {
    return getConfig('REACT_APP_MYBESSE_KEYCLOAK_REALM') || '';
};

export const getKeycloakClientId = (): string => {
    return getConfig('REACT_APP_MYBESSE_KEYCLOAK_CLIENT_ID') || '';
};

export const getActuApiEndpoint = () => {
    return getConfig('REACT_APP_MYBESSE_ACTU_URL_API') || '';
};

export const getContactApiEndpoint = () => {
    return getConfig('REACT_APP_MYBESSE_ACTU_CONTACT_API') || '';
};

export const getInfoAppli = () => {
    return {
        objetMail: 'Contact depuis le site My bessé',
        templateHeader: getConfig('REACT_APP_MYBESSE_TEMPLATE_HEADER') || '',
        templateFooter: getConfig('REACT_APP_MYBESSE_TEMPLATE_FOOTER') || '',
        templateMail: getConfig('REACT_APP_MYBESSE_TEMPLATE_MAIL') || '',
        sender: getConfig('REACT_APP_MYBESSE_SENDER') || '',
        senderName: getConfig('REACT_APP_MYBESSE_SENDER_NAME') || '',
        destinataire: getConfig('REACT_APP_MYBESSE_DESTINATAIRE') || '',
    };
};

export const getFagcUrl = () => {
    return getConfig('REACT_APP_MYBESSE_FAGC') || '';
};

export const getCargoUrl = () => {
    return getConfig('REACT_APP_MYBESSE_CARGO') || '';
};

export const getUtilisateursUrl = () => {
    return getConfig('REACT_APP_MYBESSE_UTILISATEURS') || '';
};

export const getDocsUrl = () => {
    return getConfig('REACT_APP_MYBESSE_DOCS') || '';
};

export const getClientsUrl = () => {
    return getConfig('REACT_APP_MYBESSE_CLIENTS') || '';
};

export const getToken = () => {
    return getConfig('TOKEN') || '';
};

export const getInfoMaintenance = () => {
    return {
        infoMaintenance: getConfig('REACT_APP_MYBESSE_INFOMAINTENANCE'),
        messageInfoFr: getConfig('REACT_APP_MYBESSE_MSGINFOFR'),
        messageInfoEn: getConfig('REACT_APP_MYBESSE_MSGINFOEN'),
        dates: getConfig('REACT_APP_MYBESSE_INFOMAINTENANCEDATES'),
        displayMaintenance: getConfig('REACT_APP_MYBESSE_MAINTENANCE'),
        dateFinMaintenance: getConfig('REACT_APP_MYBESSE_DATEFINMAINTENANCE'),
        messageMaintenanceFr: getConfig('REACT_APP_MYBESSE_MSGMAINTENANCEFR'),
        messageMaintenanceEn: getConfig('REACT_APP_MYBESSE_MSGMAINTENANCEEN'),
    };
};

export const getMyBesseV2Url = () => {
    return getConfig('REACT_APP_MYBESSEV2_URL') || ''
}

export const getMatomoAnalyticsManager = () => {
    return getConfig('REACT_APP_MYBESSE_MATOMO') || '';
};

export const getAcceptioCookieManager = () => {
    return getConfig('REACT_APP_MYBESSE_AXEPTIO') || '';
};