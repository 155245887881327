import Axios, {AxiosRequestConfig} from 'axios';
import createContactApi, {IApi as IContactApi} from './contact';

import {getToken} from '../Config';
/**
 * Création du client Axios
 */

/**
 * Création du client Axios
 */
const createAxiosClient = (endpoint: string) => {
    const axiosClient = Axios.create({
        baseURL: endpoint,
        // tslint:disable: quotemark
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        // tslint:enable: quotemark
    });

    /**
     * Intercepteur Axios
     */
    axiosClient.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            config.headers.common['X-Gravitee-Api-Key'] = getToken();
            // Do something before request is sent
            return config;
        },
        error => {
            // Do something with request error
            return Promise.reject(error);
        },
    );

    return axiosClient;
};

/**
 * Crée un nouveau client d'API
 * @param endpoint
 */
const create = (endpoint: string): IApi => {
    const axiosClient = createAxiosClient(endpoint);
    return {
        ...createContactApi(endpoint, axiosClient),
    };
};

// Fusion des interfaces des API en une interface unique

export type IApi = IContactApi;

export default {create};
