import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    blocText: {
      marginTop: 64,
      width: '50%',
      height: 'calc(242px - 96px)',
      background: '#F1F8FB',
      padding: '48px 32px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& img': {
        boxShadow: '5px 5px 12px 0 rgba(0, 0, 0, 0.28)',
      },
    },
    text: {
      marginTop: 28,
      borderLeft: '3px solid ' + theme.palette.secondary.main,
      '& p': {
        paddingLeft: 16,
      },
    },
    '@media all and (max-width: 1060px)': {
      blocText: {
        width: 'calc(100% - 64px)',
        maxWidth: 700,
        margin: 'auto',
        '& img': {
          width: '50%',
          minWidth: '200px',
        },
      },
    },
    '@media all and (max-width: 600px)': {
      blocText: {
        '& > div': {
          marginRight: 8,
        },
        '& img': {
          width: '171px',
          height: '171px',
          minWidth: 0,
        },
      },
    },
  } as any);

export default styles;
