import createActuApi, { IApi as IActuApi } from './actualites';

const create = (endpoint: string): IApi => {
  return {
    ...createActuApi(endpoint),
  };
};

// Fusion des interfaces des API en une interface unique

export type IApi = IActuApi;

export default { create };
