import React, { useEffect } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';
import Layout from '../../components/Layout';
import { traduction } from '../../traductions';
import InputField from '../../components/inputs/InputField';
import FormContact from './FormContact';
import { ActionCreators, Selectors } from '../../redux/ContactRedux';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoAppli } from '../../services/Config';
import { NousContacterInput } from '../../services/APIContact/swagger';
import { useCookies } from 'react-cookie';

interface Props {
  classes?: any;
}

function Contact(props: Props) {
  const dispatch = useDispatch();
  const fetching = useSelector(Selectors.postNousContacterFetching);
  const error = useSelector(Selectors.postNousContacterError);
  const confirm = useSelector(Selectors.postNousContacterConfirmation);
  const [cookies, setCookies] = useCookies();

  const handleSend = data => {
    const params = {
      contact: data,
      infoAppli: getInfoAppli(),
    } as NousContacterInput;
    dispatch(ActionCreators.postNousContacter.request(params));
  };

  // useEffect(() => {
  //   if (confirm) {
  //     const timer = setTimeout(() => {
  //       dispatch(ActionCreators.resetConfirmation());
  //     }, 60000);
  //     return () => clearTimeout(timer);
  //   }
  //   return () => {};
  // }, [confirm]);
  const langue = cookies['langue'] || 'fr';
  return (
    <Layout>
      <div className={props.classes.container}>
        <div className={props.classes.content}>
          <h2>{traduction(langue).contact.titre}</h2>
          <FormContact onSubmitForm={handleSend} fetching={fetching} langue={langue} />
          {confirm && (
            <Typography color="primary" className={props.classes.confirm}>
              {<p>{traduction(langue).contact.confirmEnvoi}</p>}
            </Typography>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default withStyles(styles)(Contact);
