import { fr } from './fr';
import { en } from './en';

export const traduction = value => {
  if (value === 'fr') {
    return fr;
  } else {
    return en;
  }
};
