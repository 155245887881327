import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { ActionCreators } from '../redux/ActualitesRedux';
import { IApi } from '../services/API/actualites';

export function* getActualites(api: IApi, action: ActionType<typeof ActionCreators.getActualites.request>) {
  try {
    const response = yield call(api.getActualites, action.payload);
    yield put(ActionCreators.getActualites.success(response));
  } catch (error) {
    yield put(ActionCreators.getActualites.failure(error));
  }
}
