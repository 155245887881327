import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    layout: {
      minHeight: 'calc(100% - 196px)',
    },
  } as any);

export default styles;
