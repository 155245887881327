import CircularProgress from '@material-ui/core/CircularProgress';
import { ConnectedRouter } from 'connected-react-router';
import React, { Component, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from '../../redux/configureStore';
import rootSaga from '../../sagas';
import history from '../../utils/history';

interface Props {
  children: ReactNode;
}

export default class ReduxProvider extends Component<Props> {
  store: Store;
  persistor: Persistor;

  constructor(props) {
    super(props);

    // Création du store Redux
    const initialState = {};
    this.store = configureStore(initialState, history);

    // Démarrage de la root saga
    //@ts-ignore
    this.store.runSaga(rootSaga);

    this.persistor = persistStore(this.store);
  }

  render() {
    return (
      <Provider store={this.store}>
        <PersistGate loading={<CircularProgress />} persistor={this.persistor}>
          <ConnectedRouter history={history}>{this.props.children}</ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}
