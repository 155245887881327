import { withStyles } from '@material-ui/core';
import React from 'react';
import { useCookies } from 'react-cookie';
import MaintenanceBloc from '../../containers/HomePage/MaintenanceBloc';
import { getNameToDisplay, logout } from '../../services/Authentification/authentification';
import Footer from '../Footer';
import Header from '../Header';
import MetaDatas from '../MetaDatas';
import styles from './styles';
import { getInfoMaintenance, getMatomoAnalyticsManager, getAcceptioCookieManager } from '../../services/Config';
import AxeptioInjector from '../../axeptio.injector';

interface Props {
  classes?: any;
  children: JSX.Element[] | JSX.Element;
  headerConnecte?: boolean;
  client?: string;
}

function Layout(props: Props) {
  const userName = getNameToDisplay();
  const [cookies, setCookies] = useCookies();
  const [cookieLangue] = useCookies(['langue']);

  const maintenance = getInfoMaintenance();
  const clientId = getAcceptioCookieManager();
  const matomoInstance = getMatomoAnalyticsManager();
  AxeptioInjector(clientId);

  return (
    <>
      {maintenance.displayMaintenance === 'true' && (
        <MaintenanceBloc maintenance={maintenance} langue={cookies['langue'] || 'fr'} />
      )}
      <MetaDatas matomoTag={matomoInstance} />
      <Header
        logout={logout}
        headerConnecte={props.headerConnecte}
        userName={userName}
        selectedLangue={cookies['langue'] ? cookieLangue['langue'] : 'fr'}
        langues={['fr', 'en']}
        onSelectedLangue={langue => setCookies('langue', langue)}
      />
      <div className={props.classes.layout}>{props.children}</div>
      <Footer langue={cookies['langue'] || 'fr'} />
    </>
  );
}

export const LayoutComponent = Layout;
export default withStyles(styles)(Layout);
