import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../styles';
import Bloc from '../../../components/Bloc';
import { traduction } from '../../../traductions';
import imageCarline from '../../../images/icone_carline.jpg';
import imageMaritime from '../../../images/icone_maritime.jpg';
import imageDoc from '../../../images/icone_document.jpg';
import {
  getFagcUrl,
  getCargoUrl,
  getDocsUrl,
  getUtilisateursUrl,
  getInfoMaintenance,
  getClientsUrl,
  getMyBesseV2Url,
} from '../../../services/Config';

interface Props {
  classes?: any;
  roles?: any;
  langue: string;
}

function ApplicationsBloc(props: Props) {
  const { roles } = props;
  const isBesse = roles && roles.find(role => role === 'ROLE_ADMINPORTAILBESSE');

  const urlFagc = getFagcUrl();
  const urlCargo = getCargoUrl();
  const urlDocs = getDocsUrl();
  const urlMyBesseV2 = getMyBesseV2Url()
  const urlUtilisateurs = getUtilisateursUrl();
  const rolesCarline =
    roles.find(role => role === 'ROLE_ACCES_CARLINE') || roles.find(role => role === 'ROLE_ACCES_CARLINE_ADMIN');
  const rolesCargo =
    roles.find(role => role === 'ROLE_ACCES_CARGO_ADMIN') || roles.find(role => role === 'ROLE_ACCES_CARGO');
  const rolesDocs =
    roles.find(role => role === 'ROLE_ACCES_PLATEFORMECOLLABORATIVE_ADMIN') ||
    roles.find(role => role === 'ROLE_ACCES_PLATEFORMECOLLABORATIVE');

  const roleMyBesseV2 = roles.find(role => role === "ROLE_CLIENT_V2")
  const isAdmin =
    roles.find(role => role === 'ROLE_ACCES_CARGO_ADMIN') || roles.find(role => role === 'ROLE_ACCES_CARLINE_ADMIN');

  const urlClientsAdmin = getClientsUrl();

  let linkFlotteAuto = traduction(props.langue).infoBulle.carline
  
  if (roleMyBesseV2) {
    linkFlotteAuto = urlMyBesseV2
  } else if (rolesCarline) {
    linkFlotteAuto = urlFagc
  }
  return (
    <div className={props.classes.contentApp}>
      <Bloc
        text="Flotte Automobile"
        variant={rolesCarline ? 'link' : 'info'}
        infoLink={linkFlotteAuto}
        image={imageCarline}
      />
      <Bloc
        text="Transport et Logistique"
        variant={rolesCargo ? 'link' : 'info'}
        infoLink={rolesCargo ? urlCargo : traduction(props.langue).infoBulle.cargo}
        image={imageMaritime}
      />
      {(isBesse || rolesDocs) && (
        <Bloc
          text="Espace Collaboratif"
          variant={rolesDocs ? 'link' : 'info'}
          infoLink={rolesDocs ? urlDocs : traduction(props.langue).infoBulle.docs}
          image={imageDoc}
        />
      )}
      {isBesse && <Bloc text="Gestion Clients" variant="link" image={imageDoc} infoLink={urlClientsAdmin} />}
      {isAdmin && <Bloc text="Gestion Utilisateurs" variant="link" infoLink={urlUtilisateurs} image={imageDoc} />}
    </div>
  );
}

export default withStyles(styles)(ApplicationsBloc);
