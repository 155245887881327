import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Layout from '../../components/Layout';
import { traduction } from '../../traductions';

interface Props {
  classes?: any;
}

function MentionsLegales(props: Props) {
  return (
    <Layout>
      <div className={props.classes.container}>
        <div className={props.classes.content}>
          <h2>{traduction('fr').mentions.titre}</h2>
          <h4>{traduction('fr').mentions.identificationTitle}</h4>
          <p
            className="paragraph-mentions"
            dangerouslySetInnerHTML={{ __html: traduction('fr').mentions.identification }}
          />
          <h4>{traduction('fr').mentions.identificationAutreTitle}</h4>
          <p
            className="paragraph-mentions"
            dangerouslySetInnerHTML={{ __html: traduction('fr').mentions.identificationAutre }}
          />
          <h4>{traduction('fr').mentions.conditionsTitle}</h4>
          <p
            className="paragraph-mentions"
            dangerouslySetInnerHTML={{ __html: traduction('fr').mentions.conditions }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default withStyles(styles)(MentionsLegales);
