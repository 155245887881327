import React from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  title?: string;
  matomoTag?: string
}

const MetaDatas = (props: IProps) => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta name="robots" content={'noindex,nofollow'} />
    <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
    <meta http-equiv='cache-control' content='no-cache' />
    <meta http-equiv='expires' content='0' />
    <meta http-equiv='pragma' content='no-cache' />

    <link rel="manifest" href={process.env.PUBLIC_URL + '/manifest.json'} />
    <link rel="icon" href={process.env.PUBLIC_URL + '/favicon.Icon'} />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content="My Bessé" />
    <link href="https://fonts.googleapis.com/css?family=Playfair+Display:400,700&display=swap" rel="stylesheet" />
    <link rel="icon" href="../../favicon.ico" />
    <title>{props.title || 'My Bessé'}</title>
    {
      props.matomoTag && sessionStorage.getItem('matomoTrackingEnabled') === 'true' &&
      <script type="text/javascript">
        {`
        window._paq = window._paq || [];
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='https://pbcanalytics.cabinet-besse.net/js/container_${props.matomoTag}.js'; s.parentNode.insertBefore(g,s);
      `}
      </script>
    }
  </Helmet>
);
export default MetaDatas;
