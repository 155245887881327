import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    paperModale: {
      padding: 64,
    },
    rootModale: {
      padding: 0,
      textAlign: 'center',
      '&:first-child': {
        paddingTop: 0,
      },
      '& h2': {
        marginBottom: 32,
        padding: 0,
        '&:before': {
          content: 'none',
        },
      },
      '& p': {
        fontSize: 18,
        fontWeight: '500',
      },
    },
  } as any);

export default styles;
