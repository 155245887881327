import React from 'react';
import { withStyles, Link } from '@material-ui/core';
import styles from './styles';
import Logo from '../../images/logo_besse.jpg';
import { traduction } from '../../traductions/index';
import SimpleMenu from '../SimpleMenu';
import Person from '@material-ui/icons/Person';
import Disconnect from '@material-ui/icons/PowerSettingsNew';

interface Props {
  classes?: any;
  langues: any;
  selectedLangue: string;
  onSelectedLangue(langue: string): void;
  headerConnecte?: boolean;
  userName?: string;
  logout(): void;
}

function Header(props: Props) {
  const { classes, langues, selectedLangue, onSelectedLangue, headerConnecte, userName, logout } = props;
  return (
    <div className={classes.header}>
      <div>
        <Link href="/">
          <img className={classes.logo} src={Logo} height={100} />
        </Link>
        {headerConnecte ? (
          <div className={classes.containerUsername}>
            <Person className={classes.person} />
            <span>
              {userName}{' '}
              {/* {client && (
                <>
                  <br />
                  <strong>{client}</strong>
                </>
              )} */}
            </span>
            <div className={classes.trait} />
            <Disconnect className={classes.disconnect} onClick={logout} />
          </div>
        ) : (
          <div className={classes.containerTel}>
            <div className={classes.trait} />
            <p className={classes.contact}>{traduction(selectedLangue).header.contact}</p>
            <Link href="tel:+330240414900">{traduction(selectedLangue).header.telephone}</Link>
          </div>
        )}
        <div className={classes.menuLangue}>
          <SimpleMenu items={langues} value={selectedLangue} onChange={onSelectedLangue} />
        </div>
      </div>
    </div>
  );
}

export const HeaderComponent = Header;
export default withStyles(styles)(Header);
