import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  ({
    primaryButton: {
      background: theme.palette.secondary.main,
      border: 'none',
      borderRadius: '2px',
      boxShadow: 'none',
      padding: '12px 40px',
      textTransform: 'initial',
      fontSize: 16,
      letterSpacing: '0.5px',
      '& span': {
        fontFamily: "'Playfair Display', serif",
      },
    },
    text: {
      textTransform: 'none',
      fontSize: 15,
      padding: 0,
      color: '#000',
      textDecoration: 'underline',
      '&:hover, &:focus, &:active, &:visited': {
        background: 'transparent',
      },
    },
  } as any);

export default styles;
