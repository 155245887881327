import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Menu, MenuItem, Button } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { traduction } from '../../traductions/index';
import styles from './styles';
import { MenuProps } from '@material-ui/core/Menu';

interface Props {
  classes?: any;
  items: string[];
  value?: string;
  onChange(value: string): void;
}

const StyledMenu = (props: MenuProps) => <Menu {...props} />;

export function SimpleMenu(props: Props) {
  const { items, value, classes, onChange } = props;

  const defaultValue = value ? value : items[0];
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [stateValue, setValue] = React.useState<null | string>(null || defaultValue);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // @ts-ignore
  const onChangeValue = (event: MouseEvent<HTMLLIElement, MouseEvent>, item) => {
    setValue(item);
    onChange(item);
    setOpen(false);
    setAnchorEl(null);
  };
  return (
    <>
      <Button className={classes.simpleMenu} aria-controls="simple-menu" aria-haspopup="true" onClick={handleToggle}>
        {stateValue && traduction('fr').langue[stateValue]}
        <KeyboardArrowDown />
      </Button>

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{
          paper: classes.dropdown,
        }}
      >
        {items
          .filter(value => value !== stateValue)
          .map((item, i) => (
            <MenuItem key={i} onClick={e => onChangeValue(e, item)}>
              {traduction('fr').langue[item]}
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export const SimpleMenuComponents = SimpleMenu;
export default withStyles(styles)(SimpleMenu);
