import {CircularProgress, withStyles} from '@material-ui/core';
import React from 'react';
import Layout from '../../components/Layout';
import styles from './styles';
import {useCookies} from 'react-cookie';
import {getRoles} from '../../services/Authentification/authentification';
import ApplicationsBloc from './ApplicationsBloc';

interface Props {
    classes?: any;
}

function EspacePersonnel(props: Props) {
    const roles = getRoles();

    const [cookies, setCookies] = useCookies();
    const langue = cookies['langue'] || 'fr';

    if (roles) {
        return (
            <Layout headerConnecte client="Nom du client">
                <div className={props.classes.container}>
                    <div className={props.classes.content}>
                        <div>
                            <h2>Bienvenue sur votre espace personnel</h2>
                            <p className="paragraphBefore">
                                <strong>Accédez aux outils digitaux de "MY BESSE"</strong>
                                <br/>
                                <span className={props.classes.styleText}>
                  Pour plus d'autonomie et un accès direct et simplifié à vos données,
                  <br/> Bessé met à votre disposition ses outils de gestion sur mesure.
                </span>
                            </p>
                        </div>
                        {roles && <ApplicationsBloc roles={roles} langue={langue}/>}
                    </div>
                </div>
            </Layout>
        );
    }
    return <CircularProgress/>;
}

export default withStyles(styles)(EspacePersonnel);
