import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Layout from '../../components/Layout';
import { traduction } from '../../traductions';
import { useCookies } from 'react-cookie';
import { AXPETIO_COOKIE_NAME, MATOMO_SESSION_NAME } from '../../axeptio.injector';

interface Props {
  classes?: any;
}

function MentionsLegales(props: Props) {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setCookies = () => {
    removeCookie(AXPETIO_COOKIE_NAME);
    sessionStorage.setItem(MATOMO_SESSION_NAME, 'false');
  };
  return (
    <Layout>
      <div className={props.classes.container}>
        <div className={props.classes.content}>
          <h2>{traduction('fr').donnees.titre}</h2>
          <p
            className="paragraph-mentions"
            dangerouslySetInnerHTML={{ __html: traduction('fr').donnees.donneesPersonnelles }}
          />
          <br />
          <br />
          <h2 id="cookies">{traduction('fr').donnees.cookiesTitre}</h2>
          <p className="paragraph-mentions" dangerouslySetInnerHTML={{ __html: traduction('fr').donnees.cookies }} />

          <p>
            Vous pouvez l’afficher de nouveau en cliquant{' '}
            <a href="/" onClick={setCookies}>
              ici.
            </a>{' '}
          </p>
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
}

export default withStyles(styles)(MentionsLegales);
