import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { traduction } from '../../../traductions';
import ImagePresentation from '../../../images/image_mybesse_home.jpg';
import Button from '../../../components/Button';
import Bloc from '../../../components/Bloc';
import BlocTextImg from '../../../components/BlocTextImg';
import bureauNantesImg from '../../../images/bureau_nantes.png';
import bureauParisImg from '../../../images/bureau_paris.jpg';

interface Props {
  classes?: any;
  langue: string;
}

function BureauxBloc(props: Props) {
  return (
    <div className={props.classes.container}>
      <div className={props.classes.content}>
        <h2>{traduction(props.langue).bureaux.nosBureaux}</h2>
        <div className={props.classes.contentBureaux}>
          <BlocTextImg
            title={traduction(props.langue).bureaux.besseNantes}
            text={traduction(props.langue).bureaux.adresseNantes}
            image={bureauNantesImg}
          />
          <BlocTextImg
            title={traduction(props.langue).bureaux.besseParis}
            text={traduction(props.langue).bureaux.adresseParis}
            image={bureauParisImg}
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(BureauxBloc);
