import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#012b65',
    },
    secondary: {
      main: '#60c5db',
    },
    error: {
      main: '#fe144a',
    },
    text: {
      hint: '#00559c',
    },
  },
  typography: {
    fontFamily: ['Playfair Display', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    button: {
      fontFamily: '"Playfair Display", serif',
    },
    body1: {
      fontFamily: '"DIN-Regular"',
    },
  },
});

export default theme;
