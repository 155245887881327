import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Header from '../../components/Header';
import PresentationBloc from './PresentationBloc';
import BureauxBloc from './BureauxBloc';
import Layout from '../../components/Layout';
import ActualitesBloc from './ActualitesBloc';
import { useCookies } from 'react-cookie';

interface Props {
  classes?: any;
  maintenance?: boolean;
}

function HomePage(props: Props) {
  const [cookies, setCookies] = useCookies();
  return (
    <Layout>
      <PresentationBloc langue={cookies['langue'] || 'fr'} />
      <ActualitesBloc langue={cookies['langue'] || 'fr'} />
      <BureauxBloc langue={cookies['langue'] || 'fr'} />
    </Layout>
  );
}

export default withStyles(styles)(HomePage);
