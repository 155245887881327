import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { ActionCreators } from '../redux/ContactRedux';
import { IApi } from '../services/APIContact';

export function* postNousContacter(api: IApi, action: ActionType<typeof ActionCreators.postNousContacter.request>) {
  try {
    yield call(api.postNousContacter, action.payload);
    yield put(ActionCreators.postNousContacter.success());
  } catch (error) {
    yield put(ActionCreators.postNousContacter.failure(error));
  }
}
