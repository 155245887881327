import React, { KeyboardEvent, MouseEvent } from 'react';
import { Button as MaterialButton, Link, Popover, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import IconArrow from '@material-ui/icons/ChevronRight';
import IconClose from '@material-ui/icons/Close';
// import { useStyles } from './styles';

interface Props {
  text: string;
  classes?: any;
  variant?: 'link' | 'info' | '';
  infoLink?: string;
  image: any;
}

export function Bloc(props: Props) {
  const { text, classes, variant, infoLink, image } = props;
  const classNameLink = variant ? classes.blocWithLink : '';

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className={classes.bloc + ' ' + classNameLink}>
      <img src={image} width="100%" />
      <p>{text}</p>
      {variant === 'link' && infoLink && (
        <Link href={infoLink} className={classes.linkOk}>
          <IconArrow />
        </Link>
      )}
      {variant === 'link' && !infoLink && (
        <Link className={classes.linkNotOk}>
          <IconClose />
        </Link>
      )}
      {variant === 'info' && infoLink && (
        <Link
          className={classes.info}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span>i</span>
        </Link>
      )}
      {variant === 'info' && infoLink && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          keepMounted
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <p dangerouslySetInnerHTML={{ __html: infoLink }} />
        </Popover>
      )}
    </div>
  );
}

export const BlocComponent = Bloc;
export default withStyles(styles)(Bloc);
