import { KeycloakError } from 'keycloak-js';
import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteProps } from 'react-router';
import { Types } from 'Types';
import * as authService from '../../services/Authentification/authentification';
import { CircularProgress } from '@material-ui/core';

interface CustomProps {}

type Props = RouteProps & CustomProps;

interface State {
  // Etat du statut d'authentification
  authenticated: boolean;
  // Erreur Keycloak
  error?: KeycloakError;
  unauthorized: boolean; // Accès non autorisé
  shouldRedirect: boolean;
}

/**
 * Composant définissant une route privée, c'est-à-dire nécessitant une authentification.
 */
class PrivateRoute extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      error: undefined,
      unauthorized: false,
      shouldRedirect: false,
    };
  }

  componentDidMount() {
    const { path } = this.props;

    if (!authService.isAuthenticated()) {
      authService
        .loginIfRequired()
        .then(authenticated => {
          if (authenticated) {
            // Si on n'a pas de statut de souscription et qu'on n'est pas sur l'url de reprise il faut
            // déclencher une reprise de souscription
            if (path !== '/espace-personnel') {
              this.setState({
                shouldRedirect: true,
              });
            } else {
              this.setState({
                authenticated: true,
                shouldRedirect: false,
              });
            }
          } else {
            this.setState({ authenticated });
          }
        })
        .catch(error => {
          this.setState({ error });
        });
    }
  }

  render() {
    const { authenticated, error, shouldRedirect, unauthorized } = this.state;
    if (error) {
      return <div>Impossible de se connecter !</div>;
    } else if (shouldRedirect) {
      // eslint-disable-next-line no-restricted-globals
      location.href = '/espace-personnel';
      return null;
    } else if (authenticated) {
      if (unauthorized) {
        // eslint-disable-next-line no-restricted-globals
        return null;
      } else {
        const { component, ...rest } = this.props;
        const Component: typeof React.Component = component as any;
        // tslint:disable-next-line: jsx-no-lambda
        return <Route {...rest} render={props => <Component {...props} />} />;
      }
    } else {
      return <CircularProgress />;
    }
  }
}

const mapStateToProps = (state: Types.RootState) => ({});

export default connect(mapStateToProps)(PrivateRoute);
