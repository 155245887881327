import { ActionType, createAsyncAction, getType, createAction } from 'typesafe-actions';

import Types from 'Types';
import { NousContacterInput } from '../../services/APIContact/swagger';

// Types

export interface ContactState {
  datas?: NousContacterInput;
  contactFetchInfos: Types.IFetchInfos;
  confirmationEnvoi: boolean;
}

// Initial state

export const initialState: ContactState = {
  datas: undefined,
  confirmationEnvoi: false,
  contactFetchInfos: {
    fetching: false,
    error: undefined,
  },
};

// Action types and action creators

const postNousContacter = createAsyncAction(
  '@@app/CONTACT/GET_CONTACT_REQUEST',
  '@@app/CONTACT/GET_CONTACT_SUCCESS',
  '@@app/CONTACT/GET_CONTACT_FAILURE',
)<NousContacterInput, void, Types.WrappedError>();

const resetConfirmation = createAction('@@app/contact/RESET_CONFIRM')<void>();
export const ActionCreators = { postNousContacter, resetConfirmation };

// Selectors
const postNousContacterError = (state: Types.RootState): any | undefined =>
  state.contact && state.contact.contactFetchInfos.error;
const postNousContacterFetching = (state: Types.RootState): boolean =>
  state.contact && state.contact.contactFetchInfos.fetching;
const postNousContacterConfirmation = (state: Types.RootState): boolean =>
  state.contact && state.contact.confirmationEnvoi;

export const Selectors = {
  postNousContacterError,
  postNousContacterFetching,
  postNousContacterConfirmation,
};
export const reducer = (
  state: ContactState = initialState,
  action: ActionType<typeof ActionCreators>,
): ContactState => {
  switch (action.type) {
    case getType(postNousContacter.request):
      return {
        ...state,
        datas: action.payload,
        confirmationEnvoi: false,
        contactFetchInfos: {
          fetching: true,
          error: undefined,
        },
      };

    case getType(postNousContacter.success):
      return {
        ...state,
        datas: undefined,
        confirmationEnvoi: true,
        contactFetchInfos: {
          fetching: false,
          error: undefined,
        },
      };

    case getType(postNousContacter.failure):
      return {
        ...state,
        confirmationEnvoi: false,
        contactFetchInfos: {
          fetching: false,
          error: action.payload,
        },
      };
    case getType(resetConfirmation):
      return {
        ...state,
        confirmationEnvoi: false,
      };
    default:
      return state;
  }
};
